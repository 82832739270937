import React, { memo, useState, useCallback } from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import config from '/src/config'
import API_ENDPOINTS from '/src/app/apiEndpoints'
import { callAPI } from '/src/utils/apiUtils'
import Watermark from '/src/storybook/icons/Watermark'
import { BOMModal } from '/src/storybook/modals/BOMModal'
import { TooltipHolder } from '/src/storybook/components/TooltipHolder'
import {
  nameHelpValues,
  priceHelpValuesBlock1,
  priceHelpValuesBlock2,
  priceHelpValuesBlock3,
  spaceHelpValuesBlock
} from "/src/routes/SizerCalculator/ResultsPane/ResultsPaneConsts"
import { Help } from './Help'
import { PerformanceHelp } from './PerformanceHelp'
import { SolutionHelp } from './SolutionHelp'
import { ThresholdNumber } from './ThresholdNumber'
import Option from './Option'
import './ResultsGrid.scss'
import './ResultsHelpTooltip.scss'


const ResultsGrid = memo(({ results, showExactOption, user, selectedForExport, setSelectedForExport, isWarm, read_part, hiddenOutput, isPartner }) => {
  const [ BOMModalData, setBOMModalData] = useState(null)
  const [ openPerformanceSubTooltip, setOpenPerformanceSubTooltip] = useState('')
  const renderThresholdNumber = useCallback((option, fieldName) => {
    let field = _.get(option, fieldName)
    if (fieldName === 'capacity') {
      field = _.round(parseFloat(field), 1)
    }
    if (!showExactOption && _.has(_.get(option, 'threshold_fields'), fieldName)) {
      return (<ThresholdNumber type={fieldName} number={field}
                               threshold={_.get(option, ['threshold_fields', fieldName])} />)
    } else {
      return field
    }
  })

  return (
    <div className="results-grid">
      <Watermark/>
      {Object.entries(results).map(([index, option]) => {
        const weka_total_cost = _.get(option, 'weka_total_cost')
        const name = _.get(option, 'instance_name')
        const displayCost = _.get(option, 'display_cost')
        const enclosures = _.get(option, 'enclosures')
        const servers = _.get(option, 'servers')
        const uSpace = _.get(option, 'u_space')
        const withCold = _.get(option, "cold_capacity") > 0 && _.get(option, "warm_cost_and_space")
        const servers_enclosures = enclosures === servers ? servers : `${servers} / ${enclosures}`
        const priceValue = displayCost && weka_total_cost ? weka_total_cost.toLocaleString() : '-'
        const spaceValue = _.isArray(uSpace) ? _.get(uSpace, 0) : uSpace
        let rowValues = [_.get(option, 'vendor'), name, servers_enclosures,
          _.get(option, 'populated_ssds'), renderThresholdNumber(option, 'capacity'),
          renderThresholdNumber(option, 'iops'), renderThresholdNumber(option, 'bw'),
          spaceValue]
        let rowValuesForClipboard = [_.get(option, 'vendor'), name, servers, enclosures,
          _.get(option, 'populated_ssds'), _.get(option, 'capacity'), _.get(option, 'iops'),
          _.get(option, 'bw'), spaceValue]
        let tooltips = [
          (<TooltipHolder content={<Help option={option} hiddenOutput={hiddenOutput || []} valuesBlock1={nameHelpValues}/>} className="result-help-tooltip"/>),
          (<TooltipHolder content={<SolutionHelp hiddenOutput={hiddenOutput || []} openPerformanceSubTooltip={openPerformanceSubTooltip} setOpenPerformanceSubTooltip={setOpenPerformanceSubTooltip} option={option} isPartner={isPartner}/>} className="result-help-tooltip"/>),
          (<TooltipHolder content={<PerformanceHelp openPerformanceSubTooltip={openPerformanceSubTooltip} setOpenPerformanceSubTooltip={setOpenPerformanceSubTooltip} option={option} read_part={read_part} isIOPS={true}/>} className={classNames("result-help-tooltip", "performance")} align={{offset: [-82, 6]}} onClick={() => setOpenPerformanceSubTooltip('')}/>),
          (<TooltipHolder content={<PerformanceHelp openPerformanceSubTooltip={openPerformanceSubTooltip} setOpenPerformanceSubTooltip={setOpenPerformanceSubTooltip} option={option} read_part={read_part}/>} className={classNames("result-help-tooltip", "performance")} align={{offset: [-82, 6]}} onClick={() => setOpenPerformanceSubTooltip('')}/>),
          (<TooltipHolder content={<Help option={option} valuesBlock1={spaceHelpValuesBlock} valuesBlock2={{}}/>} className={classNames("result-help-tooltip", {"big": withCold})} {...(withCold ? {placement: 'left'} : {})}/>)
        ]
        if (weka_total_cost) {
          rowValues.push(priceValue)
          rowValuesForClipboard.push(priceValue)
          tooltips.push(<TooltipHolder content={<Help option={option} valuesBlock1={priceHelpValuesBlock1} valuesBlock2={priceHelpValuesBlock2} valuesBlock3={priceHelpValuesBlock3}/>} className={classNames("result-help-tooltip", {"big": withCold})} {...(withCold ? {placement: 'left'} : {})}/>)
        }

        const optionProps = {option, selectedForExport, setSelectedForExport, weka_total_cost, index, isWarm, rowValues,
          tooltips, setBOMModalData}
        return <Option {...optionProps} key={`option-${index}`}/>
      })}
      <BOMModal
        BOMModalData={BOMModalData}
        setBOMModalData={setBOMModalData}
        user={user}
        callAPI={callAPI}
        API_ENDPOINTS={API_ENDPOINTS}
        mixpanelID={config.MIXPANEL_ID}
      />
    </div>
  )
})

export default ResultsGrid
