import React, {memo} from "react"
import _ from "lodash"
import classNames from "classnames"
import Info from '/src/storybook/icons/Info'
import { TooltipHolder } from '/src/storybook/components/TooltipHolder'
import { bwHelpValues, iopsHelpValues } from "../../ResultsPaneConsts";
import './PerformanceHelp.scss'


const PerformanceHelp = memo(({ setOpenPerformanceSubTooltip, openPerformanceSubTooltip, option, read_part, isIOPS }) => {
  const values = isIOPS ? iopsHelpValues(read_part) : bwHelpValues(read_part)

  return (
    <div>
      {_.map(values, (key, displayName) => {
        const value = _.get(option, key)
        const subTooltip = (
          <div>
            {_.map(_.get(option, `${key}_tooltip`), (v, k) => (
              <div key={_.kebabCase(`${displayName}-${k}-sub-tooltip`)}>
                <div><b>{k}</b></div>
                <div>{v}</div>
              </div>
            ))}
          </div>
        )
        return (
          <div key={_.kebabCase(displayName)} className={classNames({'selected': openPerformanceSubTooltip === displayName})}>
            <div><b>{displayName}</b></div>
            <div className="tooltipValue">
              <div>{value}</div>
              { !_.isUndefined(value) && value !== 0 && (
                <TooltipHolder
                  content={subTooltip}
                  holder={<Info/>}
                  holderClassName="help-icon"
                  placement="bottomLeft"
                  align={{offset: [36, -34]}}
                  className={classNames("sub-tooltip")}
                  onClick={() => setOpenPerformanceSubTooltip(openPerformanceSubTooltip === displayName ? '' : displayName)}
                />
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
})

PerformanceHelp.defaultProps = {
  isIOPS: false
}

export default PerformanceHelp