import React, { memo } from 'react'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'
import { THEMING } from '/src/app/consts'
import { Logo } from '/src/storybook/components/Logo'
import './Header.scss'


const Header = memo(({ user }) => {
  const history = useHistory()
  const isHV = _.startsWith(window.location.hostname, 'hv.')
  let title = <>Plan your <span className="colored"> Weka.</span></>
  let text = <>
    <p><span className="emphasized">IMPORTANT:</span> The sizer tool estimates performance for the indicated workload extrapolated from industry-standard benchmark test results.</p>
    <p><span className="emphasized">Actual Performance will vary. These results should not be shared directly with a customer.</span></p>
    <p><span className="emphasized">Contact your WEKA account team or <a href="mailto:partners@weka.io">partners@weka.io</a> for further guidance.</span></p>
  </>
  if (isHV) {
    title = THEMING.HV.title
    text = <>
      {_.map(THEMING.HV.text, line => (<p>{line}</p>))}
    </>
  }
  return (
    <div className="header">
      <div className="top-line">
        <Logo isHV={isHV}/>
        <a target="blank" className="documentation-link" href="https://docs.weka.io/">Open Documentation</a>
      </div>
      <div className="centered">
        <div className="welcome-message">
          Hello {_.get(user, 'name')}!
          <a onClick={() => history.push("/logout")}> (logout)</a>
        </div>
        <div className="title">{title}</div>
        <div className="sub-title">THE FILE SYSTEM FOR THOSE WHO SOLVE BIG PROBLEMS.</div>
        {text}
      </div>
    </div>
  )
})

export default Header